<template>
  <div v-if="experiment">
    <template v-for="(element, elIndex) in experiment.code.sections[this.$route.params.section].screens[this.$route.params.screen].elements">
      <div :key="elIndex" v-html="element.html" v-on:click="editElement(this.$route.params.section, this.$route.params.screen, elIndex)"></div>
    </template>
    <div v-html="experiment.code.sections[this.$route.params.section].screens[this.$route.params.screen].html"></div>
    <div v-html="'<style>' + experiment.code.style + experiment.code.sections[this.$route.params.section].style + experiment.code.sections[this.$route.params.section].screens[this.$route.params.screen].style + '</style>'"></div>
  </div>
</template>

<script>
export default {
  name: 'preview-screen',
  data() {
    return {
      experiment: null,
      message: 'No data to show'
    }
  },
  created() {
    this.load()
  },
  methods: {
    load() {
      let self = this
      var xhr = new XMLHttpRequest()
      xhr.open('GET', this.$root.apiUrl + 'v1/experiments/' + this.$route.params.id)
      xhr.setRequestHeader('Authorization', localStorage.getItem('token'))
      xhr.onload = () => {
        if (xhr.status === 401) self.$router.push({name: 'Login'})
        var response = JSON.parse(xhr.responseText)
        if (xhr.status === 200 && response.response) {
          if (response.response.code) {
            response.response.code = JSON.parse(response.response.code)
            self.experiment = response.response
          }
        }
      }
      xhr.send()
    }
  }
}
</script>
